

































import {Component, Vue} from 'vue-property-decorator';
import ProductSummary from '@/components/ProductSummary.vue';
import {getModule} from 'vuex-module-decorators';
import ProductModule from '@/store/modules/ProductModule';
import {UserProductDTO} from '@/generated';
import {RecycleScroller} from 'vue-virtual-scroller';

@Component({
    components: {
        ProductSummary,
        RecycleScroller
    }
})
export default class Products extends Vue {
    private productModule = getModule(ProductModule, this.$store);

    filterExpanded: boolean = false;
    itemSize: number = this.getItemSize();

    created() {
        const resizeHandler = this.onWindowResize.bind(this);
        window.addEventListener('resize', resizeHandler);
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('resize', resizeHandler);
        });
    }

    get productCount(): number {
        return this.productModule.products.length;
    }

    get products(): UserProductDTO[] {
        return this.productModule.products;
    }

    getItemSize(): number {
        return window.innerWidth > 768 ? 100 : 70;
    }

    onWindowResize() {
        this.itemSize = this.getItemSize();
    }
}
